import "./modal.scss";
import Emoji from "./emoji.svg";
import Socials from "./socials.svg";
import Football from "../sportIcons/football.svg";
import Avatar from "../avatar.svg"
import {
  EmailShareButton,
  FacebookShareButton,
} from 'react-share'

const Modal = ({ setShowModal, id }) => {
  return (
    <div className="preview">
      <div className="wrapper">
        <div className="modal">
          <div className="top">
            <i onClick={() => setShowModal(false)} className="fas fa-times"></i>
          </div>
          <br />
          <br />
          <div className="body">
            {/* <p className="title">Congratulations!</p>
              <p className="para">Campaign created successfully</p>
              <img className="emoji" src={Emoji} alt="icon" /> */}

<div className="top">
                      <div className="lef">
                        <img src={Avatar} alt="useravatar" />
                        <p className="name">MrBanksTips</p>
                      </div>
                      <p className="righ date">25/05/2022</p>
                    </div>

            <div className="picks">
              <div className="platform-banner"></div>
              <div className="pick">
                <div className="cont">
                  <div className="leftx">
                    <div className="uptxt">
                      <img src={Football} alt="sporticon" /> <p>Away</p>
                    </div>
                    <p className="midtxt">
                      Liverpool <strong style={{ margin: "0 .3rem" }}>x</strong>{" "}
                      Villareal
                    </p>
                    <p className="btmtxt">1X2</p>
                  </div>
                  <p className="rightx odd">4.30</p>
                </div>
              </div>
            </div>
<br />
              <div className="sharex" style={{textAlign: "center"}}>
                 <p className="info">Share</p>
                 <EmailShareButton />
                {/* <img className="socials" src={Socials} alt="socials" /> */}
              </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
