import React from "react";
import "./payment.scss";
import { PaystackButton } from "react-paystack";
import axios from "axios";
import { useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import baseUrl from "../../config";

const Payment = ({
  userEmail,
  userId,
}) => {
  const config = {
    referenceDate: new Date().getTime().toString(),
    email: userEmail,
    amount: 200000,
    metadata: {
      _id: userId,
    },
    publicKey: "pk_test_56ae2d75a1156c86c4c1ef474b7ea3818141eb32",
    // publicKey: "sk_test_507747aca228b90acbe06fd8d0e45ec422409863",
  };

  const currentUser = useSelector(({ userData }) => userData.currentUser);

  const toast = useToast()

  const onSuccess = async (reference) => {
    console.log("transaction successful", reference);

    const datax = {
      reference: reference.reference
    }
    try {
      const res = await axios.post(`${baseUrl}/user/verify-sub`, datax)
      console.log(res?.data?.data)

      try {
        const res = await axios.put(`${baseUrl}/user/update/${userId}`, {vip: true})
        console.log(res)
        window.location.reload()
      } catch(err) {
        console.log(err)
      }
    } catch(err) {
      console.log(err)
    }



    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`,
    //   },
    //   body: JSON.stringify({
    //     transRef: reference.reference,
    //     userClass,
    //     isPromoUsed: discount?.promoStatus,
    //     feeWithCommission: depositAmount,
    //   }),
    // };
    // try {
    //   await fetch(
    //     `${baseUrl}/customer/request/${reqId}/select-driver/${driverId}`,
    //     requestOptions
    //   )
    //     .then((res) => res.json())
    //     .then((res) =>
    //       toast.success(res.message, {
    //         position: toast.POSITION.TOP_CENTER,
    //       })
    //     )
    //     .then((res) => (window.location.href = "/dashboard/trips"));
    // } catch (error) {
    //     toast({
    //         title: 'Error',
    //         description: "Password Mismatch, Try Again.",
    //         status: 'error',
    //         position: 'top-right',
    //         duration: 5000,
    //         isClosable: true,
    //       })
    // }
  };

  const onClose = () => {
    toast({
        title: 'Error',
        description: "Payment not completed, Try Again.",
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      })
  };

  const componentProps = {
    ...config,
    text: "Subscribe to VIP",
    className: "button",
    onSuccess: (reference) => onSuccess(reference),
    onClose: onClose,
    onclick: () => console.log("yeahhhhhhh")

  };

  return (
    <div className="payment">
      <PaystackButton {...componentProps} />
    </div>
  );
};

export default Payment;