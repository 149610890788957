import './App.css';
import Navbar from './components/navbar/navbar';
import Home from './pages/home';
import {Routes, Route} from "react-router-dom"
import PickView from './pages/pickView/pickView';
import { useEffect, useState } from 'react';
import { loaduser } from './redux/actions/auth';
import { useDispatch } from 'react-redux';
import { Spinner } from '@chakra-ui/react';
import Pay from './pages/pay/pay';
import ResetPassword from './pages/resetPassword/resetPassword';
// import WhatsAppWidget from 'react-whatsapp-chat-widget';
// import "react-whatsapp-chat-widget/index.css";



function App() {
  const [isLoading, setIsloading] = useState(true)
  const dispatch = useDispatch();

  useEffect(() => {
    if(localStorage.GCTK) {
      dispatch(loaduser())
      setIsloading(false)
    }
    
  }, [])

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path='/'  element={<Home />} />
        <Route path='/:id' element={<PickView />} />
        <Route path='/pay' element={<Pay />} />
        <Route path='/reset-password/:id' element={<ResetPassword />} />
      </Routes>
      {/* <div className='ad-banner'>AD BANNER</div> */}
    </div>
  );
}

export default App;
