import "./home.scss";
import Copy from "./copy.svg";
import Share from "./share.svg";
import Football from "./sportIcons/football.svg";
import { useState, useEffect } from "react";
// import Modal from "./shareModal/modal";
import { useNavigate, Navigate } from "react-router-dom";
import AppLoader from "../components/appLoader/appLoader";
import axios from "axios";
import baseUrl from "../config";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  ModalBody,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast,
  Heading,
  Img,
  ModalCloseButton,
  Select,
  Image,
} from "@chakra-ui/react";
import Payment from "../components/payment/payment";
import { useSelector, useDispatch } from "react-redux";
// import {Adsense} from '@ctrl/react-adsense';
import { userActionTypes } from "../redux/constants/userActionTypes";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import Copy2 from './copy2.svg'
import moment from "moment";

const Home = () => {
  const [copied, setCopied] = useState(false);
  const [copiedIndex, setCopiedIndex] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState("");
  const [copied2, setCopied2] = useState(false);

  const dispatch = useDispatch();

  const toast = useToast();
  const currentUser = useSelector(({ userData }) => userData?.currentUser);

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");

  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [email2, setEmail2] = useState("");
  const [password2, setPassword2] = useState("");
  const [cpassword2, setCpassword2] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure();
  const { isOpen: isOpen4, onOpen: onOpen4, onClose: onClose4 } = useDisclosure();

  const [isLogin, setIsLogin] = useState(true);

  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [showPunters, setShowPunters] = useState(true);
  const [showPlatform, setShowPlatform] = useState(false);
  const [showOdds, setShowOdds] = useState(false);

  const [ploading, setPloading] = useState(true);
  const [punters, setPunters] = useState([]);

  const [filterOpt1, setFilterOpt1] = useState("");
  const [filterOpt2, setFilterOpt2] = useState("");
  const [filterOpt3, setFilterOpt3] = useState("Mane");

  useEffect(() => {
    axios
      .get(`${baseUrl}/punter/get-all-punters`)
      .then((res) => setPunters(res.data))
      .then((res) => setPloading(false))
      .catch((err) => console.log(err));
    // .finally(setPloading(false))
  }, []);
  const [ploading2, setPloading2] = useState(true);
  const [platforms, setPlatforms] = useState([]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/platform/get-all-platforms`)
      .then((res) => setPlatforms(res.data))
      .then((res) => setPloading2(false))
      .catch((err) => console.log(err));
    // .finally(setPloading(false))
  }, []);

  const [supportedBookies, setSupportedBookies] = useState([]);
  const [loadingq, setLoadingq] = useState(true);


  useEffect(() => {
    axios
      .get("https://convertbetcodes.com/api/supported_bookies?api_key=K0JrdjV2eDZSSGpUYTloNGk0ZUhyUUdOYlF0Q1M2WjBnNnVpV2hPei9OOW1qeElwTjNmZVZzeGpkb0dSTC80Nw==")
      .then((res) => setSupportedBookies(Object.values(res.data.data.bookies)))
      // .then((res) => setSupportedBookies(res.data))
      .then((res) => setLoadingq(false))
      .catch((err) => console.log(err));
    // .finally(setPloading(false))
  }, []);

  const filterMethods = [
    (item) =>
      item?.punter?.name.toLowerCase().includes(filterOpt1.toLowerCase()),
    (item) => item?.platform.includes(filterOpt2),
    // ((item) => item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) != -1)
  ];

  const filteredArray = posts?.filter((item) => {
    for (let i = 0; i < filterMethods.length; i++) {
      if (!filterMethods[i](item)) {
        return false;
      }
    }
    return true;
  });

  const resetFilters = () => {
    setFilterOpt1("");
    setFilterOpt2("");
  };

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  useEffect(() => {
    axios
      .get(`${baseUrl}/posts`)
      // .then(res => console.log(res))
      .then((res) => setPosts(res.data))
      .then((res) => setLoading(false))
      .catch((err) => console.log(err));
  }, []);

  const handleCopy = async (code, vip, index) => {
    setCopiedIndex(index)
    if (vip) {
      if (currentUser?.vip) {
        navigator.clipboard.writeText(code);
        await setCopied(true);
        setTimeout(() => {
          console.log("This will run after 1 second!");
          setCopied(false);
        }, 1000);
      } else {
        toast({
          title: "VIP Ticket",
          description: "Please subscribe to VIP",
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      navigator.clipboard.writeText(code);
      await setCopied(true);
      setTimeout(() => {
        console.log("This will run after 1 second!");
        setCopied(false);
      }, 1000);
    }
  };

  const handleCopy2 = async () => {
    navigator.clipboard.writeText(newCode);
    await setCopied2(true);
    setTimeout(() => {
      setCopied2(false);
    }, 1000);
  };

  const handleShare = async (id, vip) => {
    if (vip) {
      if (currentUser?.vip) {
        await setId(id);
        if (navigator.share) {
          navigator
            .share({
              title: "Getcodes Pick",
              url: `https://getcodes.netlify.app/${id}`,
            })
            .then(() => {
              console.log("Thanks for sharing!");
            })
            .catch(console.error);
        } else {
          setShowModal(true);
        }
      } else {
        toast({
          title: "VIP Ticket",
          description: "Please subscribe to VIP",
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      if (navigator.share) {
        navigator
          .share({
            title: "Getcodes Pick",
            url: `https://getcodes.netlify.app/${id}`,
          })
          .then(() => {
            console.log("Thanks for sharing!");
          })
          .catch(console.error);
      } else {
        setShowModal(true);
      }
    }
  };

  const handleNavigate = async (vip, id) => {
    if (vip) {
      if (currentUser?.vip) {
        await setId(id);
        navigate(`/${id}`, { id: id });
      } else {
        toast({
          title: "VIP Ticket",
          description: "Please subscribe to VIP",
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      await setId(id);
      navigate(`/${id}`, { id: id });
    }
  };

  const formDatax = {
    email: email2,
    fullName: fullname,
    phoneNumber: phone,
    profilePicture: "file.png",
    password: password2,
  };

  const signUp = async (e) => {
    e.preventDefault();

    if (password2 === cpassword2) {
      setLoading2(true);

      console.log(formData);
      try {
        const res = await axios.post(`${baseUrl}/user/register`, formDatax);
        toast({
          title: "Success",
          description: res?.data?.message,
          status: "success",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
        setIsLogin(true);
      } catch (error) {
        console.log(error?.response?.data?.message);
      } finally {
        setLoading2(false);
      }
    } else {
      setLoading2(false);
      toast({
        title: "Error",
        description: "Password Mismatch, Try Again.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const formData = {
    email: email,
    password: password,
  };

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);

    console.log(formData);
    try {
      const res = await axios.post(`${baseUrl}/user/login`, formData);
      console.log("helooooo", res);
      localStorage.setItem("GCTK", res?.data?.data?.token);
      localStorage.setItem("GCTKID", res?.data?.data?.userInfo?._id);
      dispatch({
        type: userActionTypes.SIGNIN_SUCCESS,
        payload: res?.data?.data?.userInfo,
      });
      onClose();
      toast({
        title: "Success",
        description: "Signed In Successfully.",
        status: "success",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error?.response?.data?.message);
      toast({
        title: "Error",
        description: error?.response?.data?.message,
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading1(false);
    }
  };

  const handlePunters = () => {
    setShowPunters(!showPunters);
    setShowPlatform(false);
    setShowOdds(false);
  };
  const handlePlatform = () => {
    setShowPunters(false);
    setShowPlatform(!showPlatform);
    setShowOdds(false);
  };
  const handleOdds = () => {
    setShowPunters(false);
    setShowPlatform(false);
    setShowOdds(!showOdds);
  };

  const handleSub = () => {
    if (currentUser) {
      navigate(`/pay?uid=${currentUser?._id}&tk=${localStorage.GCTK}`);
    } else {
      onOpen();
    }
  };


  const [bookingCode, setBookingCode] = useState("");
  const [bookieFrom, setBookieFrom] = useState("");
  const [bookieTo, setBookieTo] = useState("");
  const [loadingcc, setLoadingcc] = useState(false);

  const [newCode, setNewCode] = useState("");


  const convertCode = async () => {
    setLoadingcc(true)

    try {
      const {data} = await axios.get(`https://convertbetcodes.com/api/conversion?from=${bookieFrom}&to=${bookieTo}&booking_code=${bookingCode}&api_key=K0JrdjV2eDZSSGpUYTloNGk0ZUhyUUdOYlF0Q1M2WjBnNnVpV2hPei9OOW1qeElwTjNmZVZzeGpkb0dSTC80Nw==`)
      // console.log(data?.data?.conversion?.destination_code)
      if(data?.data?.errors.length > 0) {
        toast({
          title: "An Error Occurred",
          description: data?.data?.errors[0],
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
      });
      }
      toast({
        title: "An Error Occurred",
        description: data?.data?.errors?.charge,
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
    });
      setNewCode(data?.data?.conversion?.destination_code)
    } catch(error) {
      console.log(error)
      toast({
          title: "An Error Occurred",
          description: error?.response?.data?.message,
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
      });
    } finally {
      setLoadingcc(false)
    }
  }

  const handleConvert = async(e) => {
    e.preventDefault()

    if(currentUser) {
      if(currentUser?.vip) {
        convertCode()
      } else {
        toast({
          title: "An Error Occurred",
          description: "This Feature is for VIP Subcribers only",
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
      });
      }
    } else {
      onOpen()
    }
  }

  const [otp, setOtp] = useState("");
  const [otpShown, setOtpShown] = useState(false);
  const [fView, setFView] = useState("one");
  const [tempLoader, setTemploader] = useState(false)


  const handleForgot = () => {
    onOpen4()
  }

  const requestOtp = async() => {
    setTemploader(true);

    const datax = {
      email
    }
    try {
      const {data} = await axios.post(`${baseUrl}/user/forgot-password`, datax)
      setFView("four")
      toast({
        // title: "An Error Occurred",
        description: data?.message,
        status: "success",
        position: "top-right",
        duration: 5000,
        isClosable: true,
    });
    } catch(err) {
      toast({
        title: "An Error Occurred",
        description: err?.response?.data?.message,
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
    });
    } finally {
      setTemploader(false)
    }
    
  }

  const sendOtp = async() => {
    setFView("three")
  }

  const resetPassword = async() => {
    onClose4()
    onClose()
  }

  const memoArr = [
    "sportybet:ng",
    "msport",
    "bet9ja",
    "betking:ng",
    "betway:ng",
    "1xbet:ng"
  ]


  const handleConvertCode = (code) => {
    if(currentUser) {
      if(currentUser?.vip) {
        setBookingCode(code)
        onOpen3()
      } else {
        toast({
          title: "An Error Occurred",
          description: "This Feature is for VIP Subcribers only",
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
      });
      }
    } else {
      onOpen()
    }
  }

  return (
    <div className="home">
      <div className="content">
        <div className="left">

          <Box mt={4} border="1px solid lightgray" borderRadius={"0 0 4px 4px"}>
              <Text padding={".3rem 0"} bg="#000" color="#eee" textAlign="center" fontSize={".9rem"} fontWeight="500">Convert Bet Codes</Text>
              <form onSubmit={handleConvert}>
              <Box padding={".8rem 1rem"}>
                <input onBlur={e => setBookingCode(e.target.value)} type="text" placeholder="Enter Booking Code"
                style={{
                  fontSize:"14px",
                  color:"gray",
                  height:"38px",
                  borderRadius:"4px",
                  border: "1px solid lightgray",
                  width: "100%",
                  padding: "0 .6rem",
                  textTransform: "uppercase"
                }}
                />
                <FormControl mb={3}>
                </FormControl>
                <Flex alignItems={"center"} gap=".7rem">
                  <FormControl>
                    <FormLabel transform="translateY(5px)" fontSize={".8rem"}>Convert From</FormLabel>
                    <Select onChange={e => setBookieFrom(e.target.value)} color="lightgray" fontSize={".85rem"} cursor={"pointer"}borderRadius={"4px"} height="38px" _focus={{ boxShadow: "none" }}>
                      <option selected disabled>Select Item</option>
                      {!loadingq &&
                      supportedBookies?.filter(i => memoArr.includes(i.bookie))?.map(({bookie, img}, index) => {
                        return (
                          <option key={index} value={bookie}>{bookie}</option>
                        )
                      })}
                      {/* {memoArr?.map((i, index) => {
                        return (
                          <option key={index} value={i}>{i}</option>
                        )
                      })} */}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel transform="translateY(5px)" fontSize={".8rem"}>Convert To</FormLabel>
                    <Select onChange={e => setBookieTo(e.target.value)} color="lightgray" fontSize={".85rem"} cursor={"pointer"}borderRadius={"4px"} height="38px" _focus={{ boxShadow: "none" }}>
                      <option selected disabled>Select Item</option>
                      {!loadingq &&
                      supportedBookies?.filter(i => memoArr.includes(i.bookie))?.map(({bookie, img}, index) => {
                        return (
                          <option key={index} value={bookie}>{bookie}</option>
                        )
                      })}
                      {/* {memoArr?.map((i, index) => {
                        return (
                          <option key={index} value={i}>{i}</option>
                        )
                      })} */}
                    </Select>
                  </FormControl>
                </Flex>
              </Box>

              
             {newCode &&
              <Flex mt={1} alignItems="center" borderRadius="0" gap={"1rem"} justifyContent="center">
                  <Text whiteSpace="nowrap" fontWeight="700" fontSize={"1.2rem"}>{newCode}</Text>
                  {copied2 ? <Text fontSize=".8rem" color="#00C39A">Copied</Text> : <Image onClick={handleCopy2} cursor="pointer" src={Copy2} />}
              </Flex>}

              <Flex marginTop={"1.5rem"} padding={".7rem .5rem"} borderTop={"1px solid lightgray"} justifyContent={"center"}>
                <Button type="submit" width="70%" borderRadius={"6px"} height={"33px"} fontSize=".75rem" bg="#009942" padding="12px 18px" color="#fff">{loadingcc ? <Spinner /> : "Convert"}</Button>
              </Flex>
              </form>
          </Box>

          <div className="filters" style={{marginTop: "2rem"}}>
            <Text mb={7} fontWeight="700">
              Filter Bet Tickets
            </Text>
            <Box mt={3}>
              <Text
                mt={5}
                cursor="pointer"
                fontWeight="600"
                onClick={handlePunters}
              >
                Punters
                <ChevronDownIcon />
              </Text>
              {showPunters && (
                <Flex height={"125px"} overflowY="scroll" wrap="wrap" mt={2} gap=".7rem">
                  {punters.map(({ name }, index) => {
                    return (
                      <Badge
                        onClick={() => setFilterOpt1(name)}
                        cursor="pointer"
                        padding=".5rem 1rem"
                        borderRadius="16px"
                        colorScheme="green"
                      >
                        {name}
                      </Badge>
                    );
                  })}
                </Flex>
              )}
            </Box>
            <Box mt={3}>
              <Text
                mt={5}
                cursor="pointer"
                onClick={handlePlatform}
                fontWeight="600"
              >
                Platforms
                <ChevronDownIcon />
              </Text>
              {showPlatform && (
                <Flex wrap="wrap" mt={2} gap=".7rem">
                  {platforms.map(({ name }, index) => {
                    return (
                      <Badge
                        onClick={() => setFilterOpt2(name)}
                        cursor="pointer"
                        padding=".5rem 1rem"
                        borderRadius="16px"
                        colorScheme="green"
                      >
                        {name}
                      </Badge>
                    );
                  })}
                </Flex>
              )}
            </Box>
            <Box mt={3}>
              <Text
                mt={5}
                cursor="pointer"
                onClick={handleOdds}
                fontWeight="600"
              >
                Odds
                <ChevronDownIcon />
              </Text>
              {showOdds && (
                <Flex wrap="wrap" mt={2} gap=".7rem">
                  <Badge
                    cursor="pointer"
                    padding=".5rem 1rem"
                    borderRadius="16px"
                    colorScheme="green"
                  >
                    0 - 10
                  </Badge>
                  <Badge
                    cursor="pointer"
                    padding=".5rem 1rem"
                    borderRadius="16px"
                    colorScheme="green"
                  >
                    10 - 20
                  </Badge>
                  <Badge
                    cursor="pointer"
                    padding=".5rem 1rem"
                    borderRadius="16px"
                    colorScheme="green"
                  >
                    20 - 50
                  </Badge>
                  <Badge
                    cursor="pointer"
                    padding=".5rem 1rem"
                    borderRadius="16px"
                    colorScheme="green"
                  >
                    50 - 200
                  </Badge>
                  <Badge
                    cursor="pointer"
                    padding=".5rem 1rem"
                    borderRadius="16px"
                    colorScheme="green"
                  >
                    200+
                  </Badge>
                </Flex>
              )}
            </Box>
          </div>
          {/* <div className="ad">AD BANNER</div> */}
        </div>
        <div className="right">
          <Flex gap="1rem">
            <Button onClick={() => onOpen2()} className="fbtn" fontWeight="500" ml={4} variant="outline">Filter</Button>
            {currentUser ? (
              !currentUser?.vip && (
                <Payment
                  userEmail={currentUser?.email}
                  userId={currentUser?._id}
                />
              )
            ) : (
              <Button
                onClick={() => onOpen()}
                fontSize="14px"
                bg="#009942"
                padding="12px 18px"
                color="#fff"
              >
                Subscribe to VIP
              </Button>
            )}

                <Button
                onClick={() => onOpen3()}
                fontSize="14px"
                bg="#000"
                padding="12px 18px"
                color="#fff"
              >
                Convert Codes
              </Button>

            {/* <Badge borderRadius='10px' padding='.3rem .6rem' colorScheme='green' variant='solid'>Subscribe to VIP</Badge> */}
          </Flex>

          <Tabs mt={5} isFitted variant="enclosed">
            <TabList mb="1em">
              <Tab fontWeight="700" _active={{color: "#009942"}}>All Tickets</Tab>
              <Tab fontWeight="700" _active={{color: "#009942"}}>VIP Tickets</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
              <div className="posts">
            {loading ? (
              <AppLoader />
            ) : (
              filteredArray?.map(
                (
                  {
                    punter,
                    date,
                    ticket,
                    platform,
                    betCode,
                    _id: id,
                    vip,
                    totalOdds,
                    description,
                  },
                  index
                ) => {
                  return (
                    <div
                      style={{ position: "relative" }}
                      className="post"
                      key={index}
                    >

                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3806194735429134"
                        crossorigin="anonymous"></script>
                    <ins class="adsbygoogle"
                        style={{display: "block"}}
                        data-ad-format="fluid"
                        data-ad-layout-key="-ew+4v+h-ew+pv"
                        data-ad-client="ca-pub-3806194735429134"
                        data-ad-slot="8132378217"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>

                      <div className="top">
                        <div className="lef">
                          <Avatar src={punter?.avatar} />
                          <Box alignItems="center">
                            <Heading
                              fontSize="16px"
                              color="#303030"
                              fontWeight="800"
                            >
                              {punter?.name}
                            </Heading>
                            <a
                              href={punter?.profileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Text
                                cursor="pointer"
                                ml={1}
                                fontSize="14px"
                                transform="translateY(2px)"
                                color="gray.800"
                              >
                                @{punter?.twitterAt}
                              </Text>
                            </a>
                          </Box>
                        </div>
                        <p className="righ date">{moment(date).startOf('hour').fromNow()}</p>
                      </div>
                      <div className="middle">
                        <Text mb={3} fontWeight="600">
                          {description}
                        </Text>
                        {/* <Flex justifyContent="flex-end">
                          <Text
                            borderRadius="20px"
                            padding=".5rem"
                            fontSize="13px"
                            fontWeight="700"
                          >
                            Total Odds: {totalOdds}
                          </Text>
                        </Flex> */}
                        <Box bg="#fff" border="1px dashed #d9d9d9" className="picks">
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Img width={{base: "25%", sm: "20%", md: "14%"}} borderRadius="0 0 4px 0" transform={"translateY(-6px)"} src={platform} />
                            <HStack mt={2} paddingRight=".5rem">
                              <Text color="#9c9c9c" fontSize={".9rem"} fontWeight="700">
                                Total odds:
                              </Text>
                              <Text fontWeight="700" fontSize={".9rem"} color="#333">
                                {totalOdds}
                              </Text>
                            </HStack>
                          </Flex>

                          {vip ? (
                            !currentUser?.vip ? (
                              <Box
                                padding={[2, 4, 6]}
                                bg="#000"
                                position="absolute"
                                top="14%"
                                bottom="0"
                                left="0"
                                right="0"
                                zIndex="6"
                              >
                                <Flex
                                  marginBottom="1.8rem"
                                  justifyContent="space-between"
                                  alignItems="baseline"
                                >
                                  <Heading
                                    size="sm"
                                    fontWeight="700"
                                    color="#daa520"
                                  >
                                    VIP TICKET
                                  </Heading>
                                  <Box>
                                    <Text color="#82d1c8" fontSize={{base: ".8rem", md: "1rem"}}>
                                      Subscribe to VIP to view ticket
                                    </Text>
                                    <Text fontSize={{base: ".9rem", md: "1rem"}} color="#20d76f" fontWeight="700">
                                      ₦2,000/Month
                                    </Text>
                                  </Box>
                                </Flex>
                                {/* <Text
                                  maxW="310px"
                                  color="#82d1c8"
                                  lineHeight="20px"
                                  fontSize={{base: ".8rem", md: "1rem"}}
                                >
                                  Get 100% Access to all Tipster's VIP Games +
                                  Ad-Free Experience
                                </Text> */}
                                <Text color="#82d1c8"
                                  lineHeight="20px"
                                  fontSize={{base: ".8rem", md: "1rem"}}>Get more than 10 VIP Codes per Day</Text>
              <Text color="#82d1c8"
                                  lineHeight="20px"
                                  fontSize={{base: ".8rem", md: "1rem"}}>Unlimited Bet Code Conversions</Text>
              <Text color="#82d1c8"
                                  lineHeight="20px"
                                  fontSize={{base: ".8rem", md: "1rem"}}>Unlimited Free Codes</Text>
                              </Box>
                            ) : null
                          ) : vip ? (
                            <Text>BANNER</Text>
                          ) : null}

                          {ticket.map(
                            (
                              { team1, team2, pick, market, odd, ticket },
                              index
                            ) => {
                              return (
                                <div className="pick" key={index}>
                                  <div className="cont">
                                    <div className="leftx">
                                      <div className="uptxt">
                                        <img src={Football} alt="sporticon" />{" "}
                                        <p>{pick}</p>
                                      </div>
                                      <p className="midtxt">
                                        {team1}{" "}
                                        <strong style={{ margin: "0 .3rem" }}>
                                          x
                                        </strong>{" "}
                                        {team2}
                                      </p>
                                      <p className="btmtxt">{market}</p>
                                    </div>
                                    <p className="rightx odd">{odd}</p>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </Box>
                        <Text
                          onClick={() => handleNavigate(vip, id)}
                          textAlign="center"
                          padding=".6rem 0"
                          fontWeight="700"
                          cursor="pointer"
                          color="#eee"
                          bg="#007030"
                        >
                          View Ticket
                        </Text>
                        {/* <p
                          style={{ display: "flex", alignItems: "center" }}
                          onClick={() => handleNavigate(id)}
                          className="more"
                        >
                          View Ticket
                          <svg
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9.53033 0.46967C9.82322 0.762563 9.82322 1.23744 9.53033 1.53033L5.53033 5.53033C5.23744 5.82322 4.76256 5.82322 4.46967 5.53033L0.46967 1.53033C0.176777 1.23744 0.176777 0.762564 0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L5 3.93934L8.46967 0.46967C8.76256 0.176777 9.23744 0.176777 9.53033 0.46967Z"
                              fill="#52525B"
                            />
                          </svg>
                        </p> */}
                      </div>
                      <div className="bottom">
                        <div className="lefty">
                          {vip ? (
                            currentUser?.vip ? (
                              <Text
                                cursor="pointer"
                                fontWeight="600"
                                color="#333"
                                width={{base: "130px", md: "300px"}}
                                textAlign="center"
                                padding=".5rem 0"
                                borderRadius="0"
                                bg="#fff"
                                border="1px solid #007030"
                                onClick={handleSub}
                              >{betCode.toUpperCase()}</Text>
                            ) : (
                              <Text
                                cursor="pointer"
                                fontWeight="600"
                                color="#eee"
                                width={{base: "130px", md: "300px"}}
                                textAlign="center"
                                padding=".5rem 0"
                                borderRadius="0"
                                bg="#002e14"
                                onClick={handleSub}
                              >
                                Subscribe to VIP
                              </Text>
                            )
                          ) : !vip ? (
                            <Text
                                cursor="pointer"
                                fontWeight="600"
                                color="#333"
                                width={{base: "130px", md: "300px"}}
                                textAlign="center"
                                padding=".5rem 0"
                                borderRadius="0"
                                bg="#fff"
                                border="1px solid #007030"
                                onClick={handleSub}
                              >{betCode.toUpperCase()}</Text>
                          ) : (
                            <Text
                              cursor="pointer"
                              fontWeight="600"
                              color="#eee"
                              padding=".5rem 5rem"
                              bg="#002e14"
                              onClick={handleSub}
                            >
                              Subscribe to VIP
                            </Text>
                          )}
                        </div>
                        <div className="righty">
                          <button
                            onClick={() => handleCopy(betCode, vip, index)}
                            className="copy"
                          >
                            {copied && copiedIndex === index ? (
                              <p className="tooltip">
                                Copied <i className="fas fa-check"></i>
                              </p>
                            ) : (
                              <>
                                <p>Copy</p> <img src={Copy} alt="copycon" />
                              </>
                            )}
                          </button>
                          <button
                            onClick={() => handleShare(id, vip)}
                            className="share"
                          >
                            Share <img src={Share} alt="sharecon" />
                          </button>
                        </div>
                        
                      </div>
                      <Button
                      borderRadius="0"
                      bg="#fff"
                      border="1px solid #007030"
                      cursor="pointer"
                      fontWeight="600"
                      color="#333"
                      width={"100%"}
                      mt={3}
                      onClick={() => handleConvertCode(betCode)}>Convert Betcode</Button>
                    </div>
                  );
                }
              )
            )}
          </div>
              </TabPanel>
              <TabPanel>
              <div className="posts">
            {loading ? (
              <AppLoader />
            ) : (
              filteredArray?.filter(i => i.vip).map(
                (
                  {
                    punter,
                    date,
                    ticket,
                    platform,
                    betCode,
                    _id: id,
                    vip,
                    totalOdds,
                    description,
                  },
                  index
                ) => {
                  return (
                    <div
                      style={{ position: "relative" }}
                      className="post"
                      key={index}
                    >
                      <div className="top">
                        <div className="lef">
                          <Avatar mr={2} src={punter?.avatar} />
                          <Flex alignItems="center">
                            <Heading
                              fontSize="16px"
                              color="#303030"
                              fontWeight="800"
                            >
                              {punter?.name}
                            </Heading>
                            <a
                              href={punter?.profileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Text
                                cursor="pointer"
                                ml={1}
                                fontSize="14px"
                                transform="translateY(2px)"
                                color="gray.800"
                              >
                                @{punter?.twitterAt}
                              </Text>
                            </a>
                          </Flex>
                        </div>
                        <p className="righ date">{date.slice(0, 10)}</p>
                      </div>
                      <div className="middle">
                        <Text mb={3} fontWeight="600">
                          {description}
                        </Text>
                        {/* <Flex justifyContent="flex-end">
                          <Text
                            borderRadius="20px"
                            padding=".5rem"
                            fontSize="13px"
                            fontWeight="700"
                          >
                            Total Odds: {totalOdds}
                          </Text>
                        </Flex> */}
                        <div style={{bg: "#fff"}} className="picks">
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Img width={{base: "25%", sm: "20%", md: "14%"}} src={platform} />
                            <HStack paddingRight=".5rem">
                              <Text color="#009942" fontWeight="700">
                                Total odds:
                              </Text>
                              <Text fontWeight="700" color="#333">
                                {totalOdds}
                              </Text>
                            </HStack>
                          </Flex>

                          {vip ? (
                            !currentUser?.vip ? (
                              <Box
                                padding={[2, 4, 6]}
                                bg="#000"
                                position="absolute"
                                top="12%"
                                bottom="0"
                                left="0"
                                right="0"
                                zIndex="6"
                              >
                                <Flex
                                  marginBottom="1.8rem"
                                  justifyContent="space-between"
                                  alignItems="baseline"
                                >
                                  <Heading
                                    size="sm"
                                    fontWeight="700"
                                    color="#daa520"
                                  >
                                    VIP TICKET
                                  </Heading>
                                  <Box>
                                    <Text color="#82d1c8" fontSize={{base: ".8rem", md: "1rem"}}>
                                      Subscribe to VIP to view ticket
                                    </Text>
                                    <Text fontSize={{base: ".9rem", md: "1rem"}} color="#20d76f" fontWeight="700">
                                      ₦2,000/Month
                                    </Text>
                                  </Box>
                                </Flex>
                                <Text color="#82d1c8"
                                  lineHeight="20px"
                                  fontSize={{base: ".8rem", md: "1rem"}}>Get more than 10 VIP Codes per Day</Text>
              <Text color="#82d1c8"
                                  lineHeight="20px"
                                  fontSize={{base: ".8rem", md: "1rem"}}>Unlimited Bet Code Conversions</Text>
              <Text color="#82d1c8"
                                  lineHeight="20px"
                                  fontSize={{base: ".8rem", md: "1rem"}}>Unlimited Free Codes</Text>
                              </Box>
                            ) : null
                          ) : vip ? (
                            <Text>BANNER</Text>
                          ) : null}

                          {ticket.map(
                            (
                              { team1, team2, pick, market, odd, ticket },
                              index
                            ) => {
                              return (
                                <div className="pick" key={index}>
                                  <div className="cont">
                                    <div className="leftx">
                                      <div className="uptxt">
                                        <img src={Football} alt="sporticon" />{" "}
                                        <p>{pick}</p>
                                      </div>
                                      <p className="midtxt">
                                        {team1}{" "}
                                        <strong style={{ margin: "0 .3rem" }}>
                                          x
                                        </strong>{" "}
                                        {team2}
                                      </p>
                                      <p className="btmtxt">{market}</p>
                                    </div>
                                    <p className="rightx odd">{odd}</p>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                        <Text
                          onClick={() => handleNavigate(vip, id)}
                          textAlign="center"
                          padding=".6rem 0"
                          fontWeight="700"
                          cursor="pointer"
                          color="#eee"
                          bg="#007030"
                        >
                          View Ticket
                        </Text>
                        {/* <p
                          style={{ display: "flex", alignItems: "center" }}
                          onClick={() => handleNavigate(id)}
                          className="more"
                        >
                          View Ticket
                          <svg
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9.53033 0.46967C9.82322 0.762563 9.82322 1.23744 9.53033 1.53033L5.53033 5.53033C5.23744 5.82322 4.76256 5.82322 4.46967 5.53033L0.46967 1.53033C0.176777 1.23744 0.176777 0.762564 0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L5 3.93934L8.46967 0.46967C8.76256 0.176777 9.23744 0.176777 9.53033 0.46967Z"
                              fill="#52525B"
                            />
                          </svg>
                        </p> */}
                      </div>
                      <div className="bottom">
                        <div className="lefty">
                          {vip ? (
                            currentUser?.vip ? (
                              <Text
                                cursor="pointer"
                                fontWeight="600"
                                color="#333"
                                width={{base: "130px", md: "300px"}}
                                textAlign="center"
                                padding=".5rem 0"
                                borderRadius="0"
                                border="1px solid #007030"
                                bg="#fff"
                                onClick={handleSub}
                              >{betCode.toUpperCase()}</Text>
                            ) : (
                              <Text
                                cursor="pointer"
                                fontWeight="600"
                                color="#eee"
                                width={{base: "130px", md: "300px"}}
                                textAlign="center"
                                padding=".5rem 0"
                                borderRadius="0"
                                border="1px solid #007030"
                                bg="#002e14"
                                onClick={handleSub}
                                fontSize={{base: "13px", md: "15px", lg: "1rem"}}
                              >
                                Subscribe to VIP
                              </Text>
                            )
                          ) : !vip ? (
                            <Text
                                cursor="pointer"
                                fontWeight="600"
                                color="#333"
                                width={{base: "130px", md: "300px"}}
                                textAlign="center"
                                padding=".5rem 0"
                                borderRadius="8px 0 0 8px"
                                bg="#fff"
                                onClick={handleSub}
                              >{betCode.toUpperCase()}</Text>
                          ) : (
                            <Text
                              cursor="pointer"
                              fontWeight="600"
                              color="#eee"
                              padding=".5rem 5rem"
                              bg="#002e14"
                              onClick={handleSub}
                            >
                              Subscribe to VIP
                            </Text>
                          )}
                        </div>
                        <div className="righty">
                          <button
                            onClick={() => handleCopy(betCode, vip, index)}
                            className="copy"
                          >
                            {copied && copiedIndex === index ? (
                              <p className="tooltip">
                                Copied <i className="fas fa-check"></i>
                              </p>
                            ) : (
                              <>
                                <p>Copy</p> <img src={Copy} alt="copycon" />
                              </>
                            )}
                          </button>
                          <button
                            onClick={() => handleShare(id, vip)}
                            className="share"
                          >
                            Share <img src={Share} alt="sharecon" />
                          </button>
                        </div>
                      </div>
                      <Button
                      borderRadius="0"
                      bg="#fff"
                      border="1px solid #007030"
                      cursor="pointer"
                      fontWeight="600"
                      color="#333"
                      width={"100%"}
                      mt={3}
                      onClick={() => handleConvertCode(betCode)}>Convert Betcode</Button>
                    </div>
                  );
                }
              )
            )}
          </div>
              </TabPanel>
            </TabPanels>
          </Tabs>

        </div>
        <div className="last">AD BANNER</div>
      </div>
      {/* {showModal && <Modal setShowModal={setShowModal} id={id} />} */}

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent margin={"0 .5rem"}>
          {isLogin ? (
            <>
              {/* <img style={{width: "30%", margin: "0 auto", paddingTop: ".5rem"}} src={Logo} alt="get_codes_logo" /> */}
              <ModalHeader fontSize="24px">Sign In</ModalHeader>
              <Text mt={-5} ml={6} fontSize="13px" color="gray.400">
                Sign in to your getcodes account.
              </Text>
              {/* <ModalCloseButton /> */}

              <form onSubmit={login}>
                <ModalBody>
                  <FormControl mb={4}>
                    <FormLabel transform="translateY(8px)">
                      Email Address
                    </FormLabel>
                    <Input
                      onChange={(e) => setEmail(e.target.value)}
                      _focus={{ boxShadow: "none" }}
                      size="lg"
                      type="text"
                      fontSize="md"
                      placeholder="Enter email"
                      color="gray.500"
                      _placeholder={{ color: "#718096" }}
                    />
                  </FormControl>
                  <FormControl mb={1}>
                    <FormLabel transform="translateY(8px)">Password</FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) => setPassword(e.target.value)}
                        _focus={{ boxShadow: "none" }}
                        size="lg"
                        type={show ? "text" : "password"}
                        fontSize="md"
                        placeholder="Enter password"
                        color="gray.500"
                        _placeholder={{ color: "#718096" }}
                      />
                      <InputRightElement marginTop="5px" width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <Text onClick={handleForgot} fontSize="14px" cursor={"pointer"} _hover={{color: "#009942"}}>Forgot Password?</Text>

                  <HStack mt={4} justifyContent={"center"}>
                    <Text fontSize="14px">Don't Have an Account ? </Text>
                    <Text
                      onClick={() => setIsLogin(false)}
                      cursor="pointer"
                      fontSize="14px"
                      fontWeight="600"
                      color="#009942"
                    >
                      Sign Up
                    </Text>
                  </HStack>
                </ModalBody>

                <ModalFooter>
                  <Button variant="ghost" mr={3} onClick={onClose}>
                    Close
                  </Button>
                  <Button type="submit" colorScheme="green">
                    {loading1 ? <Spinner /> : "Sign In"}
                  </Button>
                </ModalFooter>
              </form>
            </>
          ) : (
            <>
              {/* <img style={{width: "30%", margin: "0 auto", paddingTop: ".5rem"}} src={Logo} alt="get_codes_logo" /> */}
              <ModalHeader fontSize="24px">Create Account</ModalHeader>
              <Text mt={-5} ml={6} fontSize="13px" color="gray.400">
                Sign up for a getcodes account.
              </Text>
              {/* <ModalCloseButton /> */}

              <form onSubmit={signUp}>
                <ModalBody>
                  <FormControl mb={3}>
                    <FormLabel transform="translateY(8px)">Full Name</FormLabel>
                    <Input
                      onChange={(e) => setFullname(e.target.value)}
                      _focus={{ boxShadow: "none" }}
                      size="lg"
                      type="text"
                      fontSize="md"
                      placeholder="Enter name"
                      color="gray.500"
                      _placeholder={{ color: "#718096" }}
                    />
                  </FormControl>
                  <FormControl mb={3}>
                    <FormLabel transform="translateY(8px)">
                      Email Address
                    </FormLabel>
                    <Input
                      onChange={(e) => setEmail2(e.target.value)}
                      _focus={{ boxShadow: "none" }}
                      size="lg"
                      type="email"
                      fontSize="md"
                      placeholder="Enter email"
                      color="gray.500"
                      _placeholder={{ color: "#718096" }}
                    />
                  </FormControl>
                  <FormControl mb={3}>
                    <FormLabel transform="translateY(8px)">
                      Phone Number
                    </FormLabel>
                    <Input
                      onChange={(e) => setPhone(e.target.value)}
                      _focus={{ boxShadow: "none" }}
                      size="lg"
                      type="tel"
                      fontSize="md"
                      placeholder="Enter phone"
                      color="gray.500"
                      minLength={11}
                      maxLength={11}
                      _placeholder={{ color: "#718096" }}
                    />
                  </FormControl>
                  <FormControl mb={3}>
                    <FormLabel transform="translateY(8px)">Password</FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) => setPassword2(e.target.value)}
                        _focus={{ boxShadow: "none" }}
                        size="lg"
                        type={show ? "text" : "password"}
                        fontSize="md"
                        placeholder="Enter password"
                        color="gray.500"
                        _placeholder={{ color: "#718096" }}
                      />
                      <InputRightElement marginTop="5px" width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <FormControl mb={4}>
                    <FormLabel transform="translateY(8px)">
                      Confirm Password
                    </FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) => setCpassword2(e.target.value)}
                        _focus={{ boxShadow: "none" }}
                        size="lg"
                        type={show ? "text" : "password"}
                        fontSize="md"
                        placeholder="Re-Enter password"
                        color="gray.500"
                        _placeholder={{ color: "#718096" }}
                      />
                      <InputRightElement marginTop="5px" width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <HStack>
                    <Text fontSize="14px">Have an Account Already ? </Text>
                    <Text
                      onClick={() => setIsLogin(true)}
                      cursor="pointer"
                      fontSize="14px"
                      fontWeight="600"
                      color="#009942"
                    >
                      Sign In
                    </Text>
                  </HStack>
                </ModalBody>

                <ModalFooter>
                  <Button variant="ghost" mr={3} onClick={onClose}>
                    Close
                  </Button>
                  <Button type="submit" colorScheme="green">
                    {loading2 ? <Spinner /> : "Sign Up"}
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>



      <Modal isCentered isOpen={isOpen4} onClose={onClose4}>
        <ModalOverlay />
        <ModalContent margin={"0 .5rem"}>
              <ModalHeader fontSize="24px">Forgot Password ?</ModalHeader>
              <Text mt={-5} ml={6} fontSize="13px" color="gray.400">
                Follow steps to reset your password
              </Text>

                {fView === "one" &&
                  <ModalBody>
                  <FormControl mb={4}>
                    <FormLabel transform="translateY(8px)">
                      Email Address
                    </FormLabel>
                    <Input
                      onChange={(e) => setEmail(e.target.value)}
                      _focus={{ boxShadow: "none" }}
                      size="lg"
                      type="text"
                      fontSize="md"
                      placeholder="Enter email"
                      color="gray.500"
                      _placeholder={{ color: "#718096" }}
                    />
                  </FormControl>
                  {otp && 
                  <FormControl mb={1}>
                    <FormLabel transform="translateY(8px)">OTP</FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) => setPassword(e.target.value)}
                        _focus={{ boxShadow: "none" }}
                        size="lg"
                        type={show ? "text" : "password"}
                        fontSize="md"
                        placeholder="Enter OTP"
                        color="gray.500"
                        _placeholder={{ color: "#718096" }}
                      />
                      <InputRightElement marginTop="5px" width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>}

                  <HStack mt={4} justifyContent={"center"}>
                    <Text fontSize="14px">Back to </Text>
                    <Text
                      onClick={() => onClose4()}
                      cursor="pointer"
                      fontSize="14px"
                      fontWeight="600"
                      color="#009942"
                    >
                      Log In
                    </Text>
                  </HStack>
                </ModalBody>}

                {fView === "two" &&
                  <ModalBody>
                  <FormControl mb={1}>
                    <FormLabel transform="translateY(8px)">OTP</FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) => setPassword(e.target.value)}
                        _focus={{ boxShadow: "none" }}
                        size="lg"
                        type={show ? "text" : "password"}
                        fontSize="md"
                        placeholder="Enter OTP"
                        color="gray.500"
                        _placeholder={{ color: "#718096" }}
                      />
                      <InputRightElement marginTop="5px" width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <HStack mt={4} justifyContent={"center"}>
                    <Text fontSize="14px">Back to </Text>
                    <Text
                      onClick={() => onClose4()}
                      cursor="pointer"
                      fontSize="14px"
                      fontWeight="600"
                      color="#009942"
                    >
                      Log In
                    </Text>
                  </HStack>
                </ModalBody>}

                {fView === "three" &&
                  <ModalBody>
                
                  
                  <FormControl mb={1}>
                    <FormLabel transform="translateY(8px)">New Password</FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) => setPassword(e.target.value)}
                        _focus={{ boxShadow: "none" }}
                        size="lg"
                        type={show ? "text" : "password"}
                        fontSize="md"
                        placeholder="Enter New Password"
                        color="gray.500"
                        _placeholder={{ color: "#718096" }}
                      />
                      <InputRightElement marginTop="5px" width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  
                </ModalBody>}

                {fView === "four" &&
                  <ModalBody>
                
                <Box margin={"2rem auto 0 auto"} width={"120px"} height="120px" backgroundImage={"https://cdn-icons-png.flaticon.com/512/1554/1554019.png"} backgroundSize="cover"></Box>
                <Text textAlign={"center"}>Password Reset link sent successfully!</Text>
                <Flex mb={6} justifyContent={"center"}>
                    <Button onClick={() => onClose4()} type="submit" colorScheme='green'>Go to Login</Button>
                </Flex>
                </ModalBody>}

              <ModalFooter>
                <Button variant="ghost" mr={3} onClick={onClose4}>
                  Close
                </Button>
                <Button type="submit" colorScheme="green" onClick={fView === "one" ? requestOtp : fView === "two" ? sendOtp : resetPassword}>
                  {tempLoader ? <Spinner /> : "Continue"}
                </Button>
              </ModalFooter>
              
          
        </ModalContent>
      </Modal>



      <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen2} onClose={onClose2}>
        <ModalOverlay
        // backdropFilter='auto'
        // backdropInvert='80%'
        // backdropBlur='2px'

        // backdropFilter='blur(10px) hue-rotate(90deg)'
        />
        <ModalContent margin="0 .5rem">
          <ModalHeader>Filter Tickets</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
          <div className="filters">
            {/* <Text mb={10} fontWeight="700">
              Filter Bet Tickets
            </Text> */}
            <Box mt={3}>
              <Text
                mt={5}
                cursor="pointer"
                fontWeight="600"
                onClick={handlePunters}
              >
                Punters
                <ChevronDownIcon />
              </Text>
              {showPunters && (
                <Flex height={"300px"} overflowY="scroll" wrap="wrap" mt={2} gap=".7rem">
                  {punters.map(({ name }, index) => {
                    return (
                      <Badge
                        onClick={() => setFilterOpt1(name)}
                        cursor="pointer"
                        padding=".5rem 1rem"
                        borderRadius="16px"
                        colorScheme="green"
                      >
                        {name}
                      </Badge>
                    );
                  })}
                </Flex>
              )}
            </Box>
            <Box mt={3}>
              <Text
                mt={5}
                cursor="pointer"
                onClick={handlePlatform}
                fontWeight="600"
              >
                Platforms
                <ChevronDownIcon />
              </Text>
              {showPlatform && (
                <Flex wrap="wrap" mt={2} gap=".7rem">
                  {platforms.map(({ name }, index) => {
                    return (
                      <Badge
                        onClick={() => setFilterOpt2(name)}
                        cursor="pointer"
                        padding=".5rem 1rem"
                        borderRadius="16px"
                        colorScheme="green"
                      >
                        {name}
                      </Badge>
                    );
                  })}
                </Flex>
              )}
            </Box>
            <Box mt={3}>
              <Text
                mt={5}
                cursor="pointer"
                onClick={handleOdds}
                fontWeight="600"
              >
                Odds
                <ChevronDownIcon />
              </Text>
              {showOdds && (
                <Flex wrap="wrap" mt={2} gap=".7rem">
                  <Badge
                    cursor="pointer"
                    padding=".5rem 1rem"
                    borderRadius="16px"
                    colorScheme="green"
                  >
                    0 - 10
                  </Badge>
                  <Badge
                    cursor="pointer"
                    padding=".5rem 1rem"
                    borderRadius="16px"
                    colorScheme="green"
                  >
                    10 - 20
                  </Badge>
                  <Badge
                    cursor="pointer"
                    padding=".5rem 1rem"
                    borderRadius="16px"
                    colorScheme="green"
                  >
                    20 - 50
                  </Badge>
                  <Badge
                    cursor="pointer"
                    padding=".5rem 1rem"
                    borderRadius="16px"
                    colorScheme="green"
                  >
                    50 - 200
                  </Badge>
                  <Badge
                    cursor="pointer"
                    padding=".5rem 1rem"
                    borderRadius="16px"
                    colorScheme="green"
                  >
                    200+
                  </Badge>
                </Flex>
              )}
            </Box>
          </div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='green' mr={3} onClick={onClose2}>
              Apply Filters
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>



      <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen3} onClose={onClose3}>
        <ModalOverlay />
        <ModalContent margin="0 .5rem">
          <ModalHeader>Convert Bet Codes</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
          <form onSubmit={handleConvert}>
              <Box padding={".8rem 0"}>
                <input value={bookingCode} onChange={e => setBookingCode(e.target.value)} type="text" placeholder="Enter Booking Code"
                style={{
                  fontSize:"14px",
                  color:"gray",
                  height:"38px",
                  borderRadius:"4px",
                  border: "1px solid lightgray",
                  width: "100%",
                  padding: "0 .6rem",
                  textTransform: "uppercase"
                }}
                />
                <FormControl mb={3}>
                </FormControl>
                <Flex alignItems={"center"} gap=".7rem">
                  <FormControl>
                    <FormLabel transform="translateY(5px)" fontSize={".8rem"}>Convert From</FormLabel>
                    <Select onChange={e => setBookieFrom(e.target.value)} color="lightgray" fontSize={".85rem"} cursor={"pointer"}borderRadius={"4px"} height="38px" _focus={{ boxShadow: "none" }}>
                      <option selected disabled>Select Item</option>
                      {!loadingq &&
                      supportedBookies?.filter(i => memoArr.includes(i.bookie))?.map(({bookie, img}, index) => {
                        return (
                          <option key={index} value={bookie}>{bookie}</option>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel transform="translateY(5px)" fontSize={".8rem"}>Convert To</FormLabel>
                    <Select onChange={e => setBookieTo(e.target.value)} color="lightgray" fontSize={".85rem"} cursor={"pointer"}borderRadius={"4px"} height="38px" _focus={{ boxShadow: "none" }}>
                      <option selected disabled>Select Item</option>
                      {!loadingq &&
                      supportedBookies?.filter(i => memoArr.includes(i.bookie))?.map(({bookie, img}, index) => {
                        return (
                          <option key={index} value={bookie}>{bookie}</option>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Flex>
              </Box>

              {newCode &&
              <Flex mt={1} alignItems="center" borderRadius="0" gap={"1rem"} justifyContent="center">
                  <Text whiteSpace="nowrap" fontWeight="700" fontSize={"1.2rem"}>{newCode}</Text>
                  {copied2 ? <Text fontSize=".8rem" color="#00C39A">Copied</Text> : <Image onClick={handleCopy2} cursor="pointer" src={Copy2} />}
              </Flex>}

              <Flex marginTop={"1.5rem"} padding={".7rem .5rem"} borderTop={"1px solid lightgray"} justifyContent={"center"}>
                <Button type="submit" width="70%" borderRadius={"6px"} height={"33px"} fontSize=".75rem" bg="#009942" padding="12px 18px" color="#fff">{loadingcc ? <Spinner /> : "Convert"}</Button>
              </Flex>
              </form>
          </ModalBody>

          
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Home;
