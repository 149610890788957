import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Payment from "../../components/payment/payment";
import baseUrl from "../../config";

const Pay = (props) => {

    const location = window.location.href
    const url = new URL(location).searchParams;
    const id = url.get("uid")
    const tk = url.get("tk")

    const [info, setInfo] = useState(null)
    const [loading, setLoading] = useState(true);

    const config = {
        headers: {
           "Authorization": `Bearer ${tk}`
           }
     }

    const fetchData = async() => {
        try {
            const {data} = await axios.get(`${baseUrl}/user/${id}`, config)
            console.log("payres",data)
            setInfo(data?.data)
        } catch(error) {
            console.log(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div style={{padding: "0 .5rem"}} className="pay">
            {loading ? <Flex justifyContent="center"><Spinner margin="4rem auto 0 auto" /> </Flex>: 
            <Box padding="1rem" margin="4rem auto 0 auto" maxWidth="600px" border="1px solid #d8d8d8" borderRadius="6px">
                <Text mb={5} textAlign="center">Pay for VIP Subscription</Text>
                <Flex mb={4} justifyContent="space-between">
                    <Text>Name:</Text>
                    <Text fontWeight="700">{info?.fullName}</Text>
                </Flex>
                <Flex mb={4} justifyContent="space-between">
                    <Text>Account Email:</Text>
                    <Text fontWeight="700">{info?.email}</Text>
                </Flex>
                <Flex mb={4} justifyContent="space-between">
                    <Text>Subscription Duration:</Text>
                    <Text fontWeight="700">1 Month</Text>
                </Flex>
                <Flex justifyContent="space-between">
                    <Text>Amount:</Text>
                    <Text fontWeight="700">₦2,000</Text>
                </Flex>
                
                <Flex mt={3} justifyContent="center">
                    <Payment userEmail={info?.email} userId={info?._id} />
                </Flex>
                
            </Box>}
        </div>
    )
}

export default Pay;