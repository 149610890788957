import "./navbar.scss";
import Logo from "./getlogo.png";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  Input,
  FormLabel,
  InputGroup,
  InputRightElement,
  Text,
  HStack,
  Spinner,
  useToast,
  Avatar,
  Flex,
  WrapItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Box,
  Badge,
  IconButton,
} from "@chakra-ui/react";
import { useState } from "react";
import { userActionTypes } from "../../redux/constants/userActionTypes";
import { useDispatch } from "react-redux";
import axios from "axios";
import baseUrl from "../../config";
import { useSelector } from "react-redux";
// import Hamburger from "../hamburger/hamburger";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'
import { AddIcon, DownloadIcon, EditIcon, ExternalLinkIcon, HamburgerIcon, LockIcon, PhoneIcon, RepeatIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen4, onOpen: onOpen4, onClose: onClose4 } = useDisclosure();
  const dispatch = useDispatch()
  const toast = useToast()

  const [navOpen, setNavOpen] = useState(false);
  const currentUser = useSelector(({userData})=>  userData?.currentUser);
  console.log("currentUser", currentUser);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");


  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [email2, setEmail2] = useState("");
  const [password2, setPassword2] = useState("");
  const [cpassword2, setCpassword2] = useState("");


  const [isLogin, setIsLogin] = useState(true)

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  const formDatax = {
    email: email2,
    "fullName": fullname,
    "phoneNumber": phone,
    "profilePicture": "file.png",
    "password": password2
}

  const signUp = async(e) => {
    e.preventDefault();
    

    if(password2 === cpassword2) {
      setLoading2(true);

    console.log(formData)
      try {
        const res = await axios.post(`${baseUrl}/user/register`, formDatax)
        toast({
          title: 'Success',
          description: res?.data?.message,
          status: 'success',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        })
        setIsLogin(true)
      } catch(error) {
        console.log(error?.response?.data?.message)
      } finally {
        setLoading2(false)
      }
    } else {
      setLoading2(false)
        toast({
            title: 'Error',
            description: "Password Mismatch, Try Again.",
            status: 'error',
            position: 'top-right',
            duration: 5000,
            isClosable: true,
          })
    }

  }

  const formData = {
    "email": email,
    "password": password
  }

  const login = async(e) => {
    e.preventDefault();
    setLoading(true);

    console.log(formData)
    try {
      const res = await axios.post(`${baseUrl}/user/login`, formData)
      console.log("helooooo",res)
      localStorage.setItem("GCTK", res?.data?.data?.token);
      localStorage.setItem("GCTKID", res?.data?.data?.userInfo?._id);
      dispatch({type: userActionTypes.SIGNIN_SUCCESS, payload: res?.data?.data?.userInfo})
      onClose()
      toast({
        title: 'Success',
        description: "Signed In Successfully.",
        status: 'success',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      })
    } 
    catch(error) {
      console.log(error?.response?.data?.message)
      toast({
        title: 'Error',
        description: error?.response?.data?.message,
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      })
    }
    finally {
        setLoading(false)
    }
    


  }

  const [tempLoader, setTemploader] = useState(false)


  const handleForgot = () => {
    onOpen4()
  }

  const requestOtp = async() => {
    setTemploader(true);

    const datax = {
      email
    }
    try {
      const {data} = await axios.post(`${baseUrl}/user/forgot-password`, datax)
      toast({
        // title: "An Error Occurred",
        description: data?.message,
        status: "success",
        position: "top-right",
        duration: 5000,
        isClosable: true,
    });
    } catch(err) {
      toast({
        title: "An Error Occurred",
        description: err?.response?.data?.message,
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
    });
    } finally {
      setTemploader(false)
    }
    
  }

  const logout = async () => {
    await localStorage.removeItem("GCTK")
    await localStorage.removeItem("GCTKID")
    window.location.reload()
  }

  return (
    <>
      <div className="navbar">
        <img src={Logo} alt="get_codes_logo" />
        <ul className="links">
          <a href="https://expo.dev/artifacts/eas/gPAn8FC7JHthSCbrm7EX9h.apk" target="_blank">
            {/* <Link to="https://expo.dev/artifacts/eas/vz9vrbvCpu5JeCwDRpDZ28.apk"> */}
            <li className="li">GetCodes App</li>
            {/* </Link> */}
            </a>
          {/* <li className="li">FAQ</li>
          <li className="li">Contact Us</li> */}
          {!currentUser ?
          <li onClick={onOpen} className="libtn">Sign In</li> 
          :
          <>
          <Flex alignItems='center'>
          
            <Popover>
                <PopoverTrigger>
                    <Avatar cursor='pointer' mr={2} name={currentUser?.fullName} />
                </PopoverTrigger>
                <PopoverContent cursor="pointer" _focus={{boxShadow: "none"}}>
                    <PopoverArrow />
                    {/* <PopoverCloseButton /> */}
                    <PopoverHeader onClick={logout}>Log Out</PopoverHeader>
                    {/* <PopoverBody>Are you sure you want to have that milkshake?</PopoverBody> */}
                </PopoverContent>
            </Popover>
           
            <Flex alignItems='center'>
              <Text mr={2}>{currentUser?.fullName}</Text>
              {currentUser?.vip && <Badge borderRadius='10px' padding='0 .6rem' colorScheme='green' variant='solid'>VIP</Badge>}
            </Flex>
            
          </Flex>
          
          </>}
        </ul>


        <Flex gap="1.3rem" alignItems={"center"}>

        <Text display={{sm: "block", md: "block", lg: "none"}} color="#eee" px={"2rem"} borderRadius="6px" bg="#1A1A1A" cursor={"pointer"} _hover={{color: "#009942"}} transition="250ms ease">GetCodes App</Text>
        
        {currentUser ? 
          <Popover>
              <PopoverTrigger>
                  <Avatar cursor='pointer' mr={2} name={currentUser?.fullName} />
              </PopoverTrigger>
              <PopoverContent cursor="pointer" _focus={{boxShadow: "none"}}>
                  <PopoverArrow />
                  <PopoverHeader onClick={logout}>Log Out</PopoverHeader>
                  <PopoverHeader>Download App</PopoverHeader>
              </PopoverContent>
          </Popover>
          :
          <Menu>
          <MenuButton
            as={IconButton}
            aria-label='Options'
            icon={<HamburgerIcon />}
            variant='outline'
            className="menu"
          />
          <MenuList>
            <MenuItem icon={<DownloadIcon />}>
              Download App
            </MenuItem>
            {currentUser ? 
            <MenuItem onClick={logout} icon={<EditIcon />}>
              Log Out
            </MenuItem> :
            <MenuItem onClick={() => onOpen()} icon={<LockIcon />}>
              Log In
            </MenuItem>}
          </MenuList>
        </Menu>}
        </Flex>

      </div>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent margin="0 .5rem">
         {isLogin ?
          <>
        <img style={{width: "30%", margin: "0 auto", paddingTop: ".5rem"}} src={Logo} alt="get_codes_logo" />
          <ModalHeader fontSize='24px'>Sign In</ModalHeader>
          <Text mt={-5} ml={6} fontSize='13px' color='gray.400'>Sign in to your getcodes account.</Text>
          {/* <ModalCloseButton /> */}

          <form onSubmit={login}>
            <ModalBody>
            
                <FormControl mb={4}>
                    <FormLabel transform='translateY(8px)'>Email Address</FormLabel>
                    <Input onChange={(e) => setEmail(e.target.value)} _focus={{boxShadow: "none"}} size='lg' type="text" fontSize='md' placeholder='Enter email' color='gray.500' _placeholder={{color: "#718096"}} />
                </FormControl>
                <FormControl mb={4}>
                    <FormLabel transform='translateY(8px)'>Password</FormLabel>
                    <InputGroup>
                        <Input onChange={(e) => setPassword(e.target.value)} _focus={{boxShadow: "none"}} size='lg' type={show ? "text" : "password"} fontSize='md' placeholder='Enter password' color='gray.500' _placeholder={{color: "#718096"}} />
                        <InputRightElement marginTop='5px' width='4.5rem'>
                            <Button h='1.75rem' size='sm' onClick={handleClick}>
                            {show ? 'Hide' : 'Show'}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </FormControl>

                <Text onClick={handleForgot} fontSize="14px" cursor={"pointer"} _hover={{color: "#009942"}}>Forgot Password?</Text>

                <HStack mt={4}>
                    <Text fontSize='14px'>Don't Have an Account ? </Text>
                    <Text onClick={() => setIsLogin(false)} cursor='pointer' fontSize='14px' fontWeight='600' color="#009942">Sign Up</Text>
                </HStack>
                
            </ModalBody>

            <ModalFooter>
                <Button variant="ghost" mr={3} onClick={onClose}>
                Close
                </Button>
                <Button type="submit" colorScheme="green">{loading ? <Spinner /> : "Sign In"}</Button>
            </ModalFooter>
          </form>
          </> 
          :
          <>
          {/* <img style={{width: "30%", margin: "0 auto", paddingTop: ".5rem"}} src={Logo} alt="get_codes_logo" /> */}
          <ModalHeader fontSize='24px'>Create Account</ModalHeader>
          <Text mt={-5} ml={6} fontSize='13px' color='gray.400'>Sign up for a getcodes account.</Text>
          {/* <ModalCloseButton /> */}

          <form onSubmit={signUp}>
            <ModalBody>
            
                <FormControl mb={3}>
                    <FormLabel transform='translateY(8px)'>Full Name</FormLabel>
                    <Input onChange={(e) => setFullname(e.target.value)} _focus={{boxShadow: "none"}} size='lg' type="text" fontSize='md' placeholder='Enter name' color='gray.500' _placeholder={{color: "#718096"}} />
                </FormControl>
                <FormControl mb={3}>
                    <FormLabel transform='translateY(8px)'>Email Address</FormLabel>
                    <Input onChange={(e) => setEmail2(e.target.value)} _focus={{boxShadow: "none"}} size='lg' type="email" fontSize='md' placeholder='Enter email' color='gray.500' _placeholder={{color: "#718096"}} />
                </FormControl>
                <FormControl mb={3}>
                    <FormLabel transform='translateY(8px)'>Phone Number</FormLabel>
                    <Input onChange={(e) => setPhone(e.target.value)} _focus={{boxShadow: "none"}} size='lg' type="text" fontSize='md' placeholder='Enter phone' color='gray.500' _placeholder={{color: "#718096"}} />
                </FormControl>
                <FormControl mb={3}>
                    <FormLabel transform='translateY(8px)'>Password</FormLabel>
                    <InputGroup>
                        <Input onChange={(e) => setPassword2(e.target.value)} _focus={{boxShadow: "none"}} size='lg' type={show ? "text" : "password"} fontSize='md' placeholder='Enter password' color='gray.500' _placeholder={{color: "#718096"}} />
                        <InputRightElement marginTop='5px' width='4.5rem'>
                            <Button h='1.75rem' size='sm' onClick={handleClick}>
                            {show ? 'Hide' : 'Show'}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </FormControl>
                <FormControl mb={4}>
                    <FormLabel transform='translateY(8px)'>Confirm Password</FormLabel>
                    <InputGroup>
                        <Input onChange={(e) => setCpassword2(e.target.value)} _focus={{boxShadow: "none"}} size='lg' type={show ? "text" : "password"} fontSize='md' placeholder='Re-Enter password' color='gray.500' _placeholder={{color: "#718096"}} />
                        <InputRightElement marginTop='5px' width='4.5rem'>
                            <Button h='1.75rem' size='sm' onClick={handleClick}>
                            {show ? 'Hide' : 'Show'}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </FormControl>

                <HStack>
                    <Text fontSize='14px'>Have an Account Already ? </Text>
                    <Text onClick={() => setIsLogin(true)} cursor='pointer' fontSize='14px' fontWeight='600' color="#009942">Sign In</Text>
                </HStack>
                
            </ModalBody>

            <ModalFooter>
                <Button variant="ghost" mr={3} onClick={onClose}>
                Close
                </Button>
                <Button type="submit" colorScheme="green">{loading2 ? <Spinner /> : "Sign Up"}</Button>
            </ModalFooter>
          </form>
          </> }
        </ModalContent>
      </Modal>

      <Modal isCentered isOpen={isOpen4} onClose={onClose4}>
        <ModalOverlay />
        <ModalContent margin={"0 .5rem"}>
              <ModalHeader fontSize="24px">Forgot Password ?</ModalHeader>
              <Text mt={-5} ml={6} fontSize="13px" color="gray.400">
                Follow steps to reset your password
              </Text>
                  <ModalBody>
                  <FormControl mb={4}>
                    <FormLabel transform="translateY(8px)">
                      Email Address
                    </FormLabel>
                    <Input
                      onChange={(e) => setEmail(e.target.value)}
                      _focus={{ boxShadow: "none" }}
                      size="lg"
                      type="text"
                      fontSize="md"
                      placeholder="Enter email"
                      color="gray.500"
                      _placeholder={{ color: "#718096" }}
                    />
                  </FormControl>
                  
                </ModalBody>

              <ModalFooter>
                <Button variant="ghost" mr={3} onClick={onClose4}>
                  Close
                </Button>
                <Button type="submit" colorScheme="green" onClick={requestOtp}>
                  {tempLoader ? <Spinner /> : "Continue"}
                </Button>
              </ModalFooter>
              
          
        </ModalContent>
      </Modal>
    </>
  );
};

export default Navbar;
