import { Box, Button, Flex, FormControl, FormLabel, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalContent, Spinner, Text, useDisclosure, useToast } from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Payment from "../../components/payment/payment";
import baseUrl from "../../config";

const ResetPassword = (props) => {

    const location = window.location.href
    const url = new URL(location).searchParams;
    const {id} = useParams();

    const navigate = useNavigate();
    const toast = useToast()
    

    const [password, setPassword] = useState(null)
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [show, setShow] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleClick = () => {
        setShow(!show)
    }


    const changePassword = async() => {
        setLoading2(true)
        const datax = {
            password,
            id
        }
        try {
            const {data} = await axios.post(`${baseUrl}/user/reset-password`, datax)
            console.log("payres", data)
            toast({
                // title: "An Error Occurred",
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
            onOpen()
        } catch(error) {
            console.log(error?.response?.data?.message)
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading2(false)
        }
    }


    return (
        <div style={{padding: "0 .5rem"}} className="pay">
            <Box padding="1rem" margin="4rem auto 0 auto" maxWidth="600px" border="1px solid #d8d8d8" borderRadius="6px">
                <Text mb={5} textAlign="center" fontWeight={"600"} fontSize={["1.1rem", "1.2rem"]}>Reset your password</Text>
                <FormControl mb={1}>
                    <FormLabel transform="translateY(8px)">New Password</FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) => setPassword(e.target.value)}
                        _focus={{ boxShadow: "none" }}
                        size="lg"
                        type={show ? "text" : "password"}
                        fontSize="md"
                        placeholder="Enter New Password"
                        color="gray.500"
                        _placeholder={{ color: "#718096" }}
                      />
                      <InputRightElement marginTop="5px" width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                
                <Flex mt={3} justifyContent="center">
                    <Button onClick={changePassword} colorScheme="green" >{loading2 ? <Spinner /> : "Update"}</Button>
                </Flex>
                
            </Box>

            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalContent margin={"0 .5rem"}>
                <ModalBody>
                    
                    <Box margin={"2rem auto 0 auto"} width={"120px"} height="120px" backgroundImage={"https://cdn-icons-png.flaticon.com/512/1554/1554019.png"} backgroundSize="cover"></Box>
                    <Text textAlign={"center"}>Password changed successfully!</Text>
                    <Flex mb={6} justifyContent={"center"}>
                        <Button onClick={() => navigate('/')} type="submit" colorScheme='green'>Go to Login</Button>
                    </Flex>
                </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default ResetPassword;