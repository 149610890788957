import "./pickview.scss";
import Copy from "../copy.svg";
import Share from "../share.svg";
import Football from "../sportIcons/football.svg";
import { useEffect, useState } from "react";
import Modal from "../shareModal/modal";
import {Link, useNavigate, useParams} from "react-router-dom"
import axios from "axios";
import AppLoader from "../../components/appLoader/appLoader";
import { Avatar, Box, Flex, Heading, HStack, Img, Text } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import baseUrl from "../../config";

const PickView = () => {
  const currentUser = useSelector(({ userData }) => userData?.currentUser);
  const [copied, setCopied] = useState(false);
 const [showModal, setShowModal] = useState(false);
 const [post, setPost] = useState(null);
 const [loading, setLoading] = useState(true);

 const navigate = useNavigate()

 const {id} = useParams();
 console.log(id);

 useEffect(() => {
  axios.get(`${baseUrl}/posts/${id}`)
      // .then(res => console.log(res))
      .then(res => setPost(res.data))
      .then(res => setLoading(false))
      .catch(err => console.log(err))
}, [])

  const handleCopy = async (code) => {
    navigator.clipboard.writeText(code)
    await setCopied(true)
    setTimeout(() => {
        console.log('This will run after 1 second!')
        setCopied(false)
      }, 1000);
}

const handleShare = async (id) => {
//   await setId(id)
  if (navigator.share) {
    navigator.share({
      title: 'Getcodes Pick',
      url: `https://getcodes.ng/${id}`
    }).then(() => {
      console.log('Thanks for sharing!');
    })
    .catch(console.error);
  } else {
    setShowModal(true)
  }
}
  
const handleSub = () => {
  if (currentUser) {
    // navigate(`/pay?uid=${currentUser?._id}&tk=${localStorage.GCTK}`);
  } else {
    // onOpen();
  }
};

  return (
    <div className="home">
      <div className="content">
        <div className="left">
          <div className="ad">AD BANNER</div>
          <div className="ad">AD BANNER</div>
          <div className="ad">AD BANNER</div>
        </div>
        <div className="right">
          <Link to='/'>
            <Flex cursor='pointer' ml={5} alignItems='center' whiteSpace="nowrap" mb={4} onClick={() => navigate("/")}>
              <ArrowBackIcon mr={1} />
              <Text>Back</Text>
            </Flex>
          </Link>
          
          <div className="posts">
                 {loading ? <AppLoader /> :
                   <div
                   style={{ position: "relative" }}
                   className="post"
                 >
                   <div className="top">
                     <div className="lef">
                       <Avatar mr={2} src={post?.punter?.avatar} />
                       <Flex alignItems="center">
                         <Heading
                           fontSize="16px"
                           color="#303030"
                           fontWeight="800"
                         >
                           {post?.punter?.name}
                         </Heading>
                         <a
                           href={post?.punter?.profileUrl}
                           target="_blank"
                           rel="noopener noreferrer"
                         >
                           <Text
                             cursor="pointer"
                             ml={1}
                             fontSize="14px"
                             transform="translateY(2px)"
                             color="gray.800"
                           >
                             @{post?.punter?.twitterAt}
                           </Text>
                         </a>
                       </Flex>
                     </div>
                     <p className="righ date">{post?.date.slice(0, 10)}</p>
                   </div>
                   <div className="middle">
                     <Text mb={3} fontWeight="600">
                       {post?.description}
                     </Text>
                     {/* <Flex justifyContent="flex-end">
                       <Text
                         borderRadius="20px"
                         padding=".5rem"
                         fontSize="13px"
                         fontWeight="700"
                       >
                         Total Odds: {totalOdds}
                       </Text>
                     </Flex> */}
                     <div className="picks" style={{height: "auto"}}>
                       <Flex
                         justifyContent="space-between"
                         alignItems="center"
                       >
                         <Img width="14%" src={post?.platform} />
                         <HStack paddingRight=".5rem">
                           <Text color="#009942" fontWeight="700">
                             Total odds:
                           </Text>
                           <Text fontWeight="700" color="#333">
                             {post?.totalOdds}
                           </Text>
                         </HStack>
                       </Flex>

                       {post?.vip ? (
                         !currentUser?.vip ? (
                           <Box
                             padding="1rem"
                             bg="#000"
                             position="absolute"
                             top="20%"
                             bottom="0"
                             left="0"
                             right="0"
                             zIndex="6"
                           >
                             <Flex
                               marginBottom="3rem"
                               justifyContent="space-between"
                               alignItems="baseline"
                             >
                               <Heading
                                 size="sm"
                                 fontWeight="700"
                                 color="#daa520"
                               >
                                 VIP TICKET
                               </Heading>
                               <Box>
                                 <Text color="#82d1c8">
                                   Subscribe to VIP to view ticket
                                 </Text>
                                 <Text color="#20d76f" fontWeight="700">
                                   ₦1,000/Month
                                 </Text>
                               </Box>
                             </Flex>
                             <Text
                               maxW="310px"
                               color="#82d1c8"
                               lineHeight="20px"
                             >
                               Get 100% Access to all Tipster's VIP Games +
                               Ad-Free Experience
                             </Text>
                           </Box>
                         ) : null
                       ) : post?.vip ? (
                         <Text>BANNER</Text>
                       ) : null}

                       {post?.ticket.map(
                         (
                           { team1, team2, pick, market, odd, ticket },
                           index
                         ) => {
                           return (
                             <Box bg="#fff" className="pick" key={index}>
                               <div className="cont">
                                 <div className="leftx">
                                   <div className="uptxt">
                                     <img src={Football} alt="sporticon" />{" "}
                                     <p>{pick}</p>
                                   </div>
                                   <p className="midtxt">
                                     {team1}{" "}
                                     <strong style={{ margin: "0 .3rem" }}>
                                       x
                                     </strong>{" "}
                                     {team2}
                                   </p>
                                   <p className="btmtxt">{market}</p>
                                 </div>
                                 <p className="rightx odd">{odd}</p>
                               </div>
                             </Box>
                           );
                         }
                       )}
                     </div>
                    
                    
                   </div>
                   <div className="bottom">
                     <div className="lefty">
                       {post?.vip ? (
                         currentUser?.vip ? (
                          <Text
                          cursor="pointer"
                          fontWeight="600"
                          color="#333"
                          width={{base: "130px", md: "300px"}}
                          textAlign="center"
                          padding=".5rem 0"
                          borderRadius="8px 0 0 8px"
                          bg="#fff"
                          border="1px solid #007030"
                          onClick={handleSub}
                        >{post?.betCode.toUpperCase()}</Text>
                         ) : (
                           <Text
                             cursor="pointer"
                             fontWeight="600"
                             color="#eee"
                             padding=".5rem 5rem"
                             bg="#002e14"
                             onClick={handleSub}
                           >
                             Subscribe to VIP
                           </Text>
                         )
                       ) : !post?.vip ? (
                        <Text
                        cursor="pointer"
                        fontWeight="600"
                        color="#333"
                        width={{base: "130px", md: "300px"}}
                        textAlign="center"
                        padding=".5rem 0"
                        borderRadius="0"
                        bg="#fff"
                        border="1px solid #007030"
                        onClick={handleSub}
                      >{post?.betCode.toUpperCase()}</Text>
                       ) : (
                         <Text
                           cursor="pointer"
                           fontWeight="600"
                           color="#eee"
                           padding=".5rem 5rem"
                           bg="#002e14"
                           onClick={handleSub}
                         >
                           Subscribe to VIP
                         </Text>
                       )}
                     </div>
                     <div className="righty">
                       <button
                         onClick={() => handleCopy(post?.betCode, post?.vip)}
                         className="copy"
                       >
                         {copied ? (
                           <p className="tooltip">
                             Copied <i className="fas fa-check"></i>
                           </p>
                         ) : (
                           <>
                             <p>Copy</p> <img src={Copy} alt="copycon" />
                           </>
                         )}
                       </button>
                       <button
                         onClick={() => handleShare(id, post?.vip)}
                         className="share"
                       >
                         Share <img src={Share} alt="sharecon" />
                       </button>
                     </div>
                   </div>
                 </div>}
          </div>
        </div>
        <div className="last">AD BANNER</div>
      </div>
      {showModal && <Modal setShowModal={setShowModal} id={id}/>}
    </div>
  );
};

export default PickView;
